export interface Work {
    id: number
    name: string,
    info: string,
    release_date: string,
    platform: string,
    demo: Array<string>,
    url: string
}

export default [
    {
        id: 1,
        name: `“完美”的世界`,
        info: `你将扮演一名“委托人”的角色，
与一个名为伊特·伊金克的心理医生一起，
为了见到“完美”的世界，
而在一座名为“海蓝镇”的小镇上替大家解决烦恼的故事。

不过说到底，什么又是“完美”呢？`,
        release_date: '2016年7月31日',
        platform: 'Windows'
    },
    {
        id: 2,
        name: '带我下地狱',
        info: `苏玛是一个无论各种方面都十分“普通”，然而却有着“玛丽苏”气质的女孩。
从小到大，因为这种气质，她都无时无刻的受到追捧。

——直到有一天，叛逆期中的苏玛厌倦了这一切，不再想要被人追捧。

苏玛：“好想下地狱啊。”`,
        release_date: '2016年10月9日',
        platform: 'Windows'
    },
    {
        id: 3,
        name: '一个蛋',
        info: `你是一个蛋，但你不知道你是个什么蛋——
所以，你得在这个桌子上，想办法找出自己的真实身份。`,
        release_date: '2016年10月22日',
        platform: 'Windows',
        url: 'https://gitee.com/arfies/lab_1bit/attach_files/714659/download/%E4%B8%80%E4%B8%AA%E8%9B%8B.zip'
    },
    {
        id: 4,
        name: '极小型星球',
        info: `按照“星际探索计划”
幸存的一对兄妹来到了一颗适宜居住的星球上，
开始了全新的生活……

只是，这颗星球会不会太小了呢？`,
        release_date: '2017年2月10日',
        platform: 'Windows',
        url: 'https://gitee.com/arfies/lab_1bit/attach_files/714662/download/%E6%9E%81%E5%B0%8F%E5%9E%8B%E6%98%9F%E7%90%83.zip'
    },
    {
        id: 5,
        name: '响',
        info: `灵魂与肉体，
是重获新生不可或缺的条件，
为了回到现实，
小女孩开始了寻找自己……

或者别人肉体的旅程。`,
        release_date: '2017年11月6日',
        platform: 'Windows',
        url: 'https://gitee.com/arfies/lab_1bit/attach_files/714667/download/%E5%93%8D.zip'
    },
    {
        id: 6,
        name: '一锅粥',
        info: `你得了喝不到粥就会死的病，
所以赶紧做碗粥给自己喝吧！`,
        release_date: '2017年11月6日',
        platform: 'Windows',
        url: 'https://gitee.com/arfies/lab_1bit/attach_files/714669/download/%E4%B8%80%E9%94%85%E7%B2%A5.zip'
    },
    {
        id: 7,
        name: '地牢探险家',
        info: `这是一个表面兄弟情的故事。

你的目的就是要在不被勇者讨厌的情况下，
想办法在地牢里骗勇者花钱
——但是注意，别把自己的钱花完了！`,
        release_date: '2018年2月28日',
        platform: 'Windows',
        url: 'https://gitee.com/arfies/lab_1bit/attach_files/714670/download/dungeon%20adventurer1.6.zip'
    },
    {
        id: 8,
        name: '西塔海姆游乐园',
        info: `您是游乐园三百年来的第一位客人，
因此我们为您准备好了一场体验救世主的小小旅程`,
        release_date: '2018年7月19日',
        platform: 'Windows',
        url: 'https://gitee.com/arfies/lab_1bit/attach_files/714672/download/%E8%A5%BF%E5%A1%94%E6%B5%B7%E5%A7%86%E6%B8%B8%E4%B9%90%E5%9B%AD.zip'
    },
    {
        id: 9,
        name: 'Game^2',
        info: `操控黄色笑脸，拿起大锤，在电子世界里敲敲敲！
不断深入，敲敲敲！`,
        release_date: '2018年8月7日',
        platform: 'Windows',
        url: 'https://gitee.com/arfies/lab_1bit/attach_files/714677/download/Game%5E2.zip'
    },
    {
        id: 10,
        name: '穷神迷宫',
        info: `在一个既没有穷神也没有迷宫的地下城市，
为了寻找不见的妹妹，
少女纳苏，开始了寻找妹妹的旅程`,
        release_date: '2019年5月5日',
        platform: 'Windows',
        url: 'https://gitee.com/arfies/lab_1bit/attach_files/714678/download/Labyrinth%20of%20poverty.zip'
    },
    {
        id: 11,
        name: '域中之匣',
        info: `Pipit在黑市购买了一个问题颇多的二手匣子，
而想要正常使用，
却还需要面临一场艰难的抉择`,
        release_date: '2019年8月30日',
        platform: 'Windows',
        url: 'https://gitee.com/arfies/lab_1bit/attach_files/714679/download/%E5%9F%9F%E4%B8%AD%E4%B9%8B%E5%8C%A31.04.zip'
    },
    {
        id: 12,
        name: '漫游永夜之墟',
        info: `意识与身躯，是作为人类不可或缺的条件，
聆听呢喃的呓语，你开始了漫游永夜之墟的旅程`,
        release_date: '2020年11月1日',
        platform: 'Windows',
        url: 'https://gitee.com/arfies/lab_1bit/attach_files/714681/download/%E6%BC%AB%E6%B8%B8%E5%A4%9C%E6%B0%B8%E5%A4%9C%E4%B9%8B%E5%A2%9FV1.2.zip'
    },
    {
        id: 13,
        name: '带饭',
        info: `厨娘系列第四弹
演示流程的范例游戏`,
        release_date: '2019年12月14日',
        platform: 'Windows',
        url: 'https://gitee.com/arfies/lab_1bit/attach_files/714682/download/DIe%20FUn.zip'
    }
] as Array<Work>
