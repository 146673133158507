






















import Vue from 'vue'
import Component from "vue-class-component";
import Gallery from "@/components/Gallery.vue";
import data, {Work} from '@/data/data'
import WorkCard from "@/components/WorkCard.vue";

@Component({
  components: {WorkCard, Gallery}
})
export default class Home extends Vue {
  works: Array<Work> = data;
}
