




















import Vue from "vue";

export default Vue.extend({
  name: "App",

  data: () => ({
    //
  }),

  methods: {
    home(): void {
      this.$router.push("/");
    }
  }
});
