






































import Vue from "vue";
import {Prop} from "vue-property-decorator";
import Component from "vue-class-component";
import data, {Work} from "@/data/data";

@Component({})
export default class WorkDetail extends Vue {
  @Prop({
    type: String
  }) id!: string;

  item: Work | undefined = data.find(it => it.id === +this.id);

  download(): void {
    const dl = document.createElement("a");
    dl.href = this.item?.url ?? '';
    dl.download = '';
    dl.click();
  }
}
